import React, { useEffect, useState } from "react";
import { BiLogOut } from "react-icons/bi";
import { BsChatDots } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import { sideMenu } from "./Menu";

const Sidebar = ({ show_navlabel, setUser }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");

  //to keep track of the active menu item even page refreshes
  useEffect(() => {
    const path = window.location.pathname;
    const label = sideMenu.find((item) => item?.to === path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, []);
  return (
    <div
      className={
        show_navlabel
          ? "p-3 pt-20 bg-white border-r"
          : "p-1 pt-16 bg-white border-r"
      }
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          {sideMenu.map((item, key) => {
            const { label, to, Icon, children } = item;
            const isActive = activeMenuItem === item.label;
            return (
              <div key={key} className="sidebar-item">
                {item?.children ? (
                  <Dropdown
                    show_navlabel={!!show_navlabel}
                    label={label}
                    activeMenuItem={activeMenuItem}
                    setActiveMenuItem={setActiveMenuItem}
                    to={to}
                    Icon={Icon}
                    options={children}
                  />
                ) : (
                  <Link
                    className={`pageLink ${
                      isActive
                        ? show_navlabel
                          ? "bg-gray-200"
                          : "flex items-center justify-center"
                        : show_navlabel
                        ? ""
                        : "flex items-center justify-center"
                    }`}
                    title={label}
                    to={to}
                    onClick={() => setActiveMenuItem(item.label)}
                  >
                    <Icon />
                    {show_navlabel && <span className="ml-3">{label}</span>}
                  </Link>
                )}
              </div>
            );
          })}
        </div>

        <div>
          <div>
            <Link
              to={""}
              className={
                show_navlabel
                  ? "pageLink"
                  : "pageLink flex items-center justify-center"
              }
            >
              <BsChatDots />{" "}
              {show_navlabel && <span className="ml-3">Support </span>}
            </Link>
          </div>

          <div>
            <Link
              to={""}
              className={
                show_navlabel
                  ? "pageLink"
                  : "pageLink flex items-center justify-center"
              }
            >
              <FiSettings />
              {show_navlabel && <span className="ml-3">Account Setting</span>}
            </Link>
          </div>

          <div>
            <Link
              onClick={() => localStorage.clear()}
              className={
                show_navlabel
                  ? "text-red-400 pageLink"
                  : "text-red-400 pageLink flex items-center justify-center"
              }
            >
              <BiLogOut />
              {show_navlabel && <span className="ml-3">Logout</span>}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
