import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { InputField, Modal, Table } from "../../components";
import EditToolbar from "../../components/Table/EditToolbar";

const columns = [
  { field: "id", headerName: "id", width: 100 },
  { field: "level", headerName: "Publication", width: 100 },
  { field: "name", headerName: "Domain", width: 100 },
  { field: "created_at", headerName: "Genre", width: 100 },
  { field: "updated_at", headerName: "Price", width: 100 },
];

const rows = [
  {
    id: 8,
    publication: "1stDayFresh",
    domain: "1stdayfresh.com",
    da: 100,
    price: 12000,
    tat: null,
    follow: "Do-follow",
    image: true,
    indexed: true,
    news: false,
    sponsored: false,
    notes: null,
    sample: null,
    region: { id: 1, description: "India" },
    type: { id: 1, description: "Feature" },
    genre: { id: 1, description: "Lifestyle | Ent" },
  },
  {
    id: 1,
    publication: "101domain.com",
    domain: "101domain.com",
    da: 63,
    price: 6000,
    tat: "Up to 1 week",
    follow: "",
    image: false,
    indexed: false,
    news: false,
    sponsored: false,
    notes: "Elite publications",
    sample: "",
    region: { id: 1, description: "India" },
    type: { id: 1, description: "Feature" },
    genre: { id: 1, description: "Lifestyle | Ent" },
  },
];

export default function Roles() {
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [level, setLevel] = useState("");

  const [modalType, setModalType] = useState("newRole");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editingRoleId, setEditingRoleId] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/roles/`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewRole = () => {
    setRoleName("");
    setLevel("");
    setModalType("newRole");
    handleModal();
  };

  const handleEditRole = (role) => {
    setEditingRoleId(role.id);
    setRoleName(role.name);
    setLevel(role.level);
    setModalType("editRole");
    handleModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const role = {
      roleName,
      level,
    };

    if (modalType === "newRole") {
      axios
        .post(`${process.env.REACT_APP_PUBLIC_API}/api/roles/addRole`, role)
        .then(() => {
          setShowConfirmation(true);
          setRoleName("");
          setLevel("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (modalType === "editRole" && editingRoleId) {
      axios
        .put(`${process.env.REACT_APP_PUBLIC_API}/api/roles/updateRole`, {
          id: editingRoleId,
          roleName: roleName,
          level: level,
        })
        .then(() => {
          setShowConfirmation(true);
          setRoleName("");
          setLevel("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      {modalType === "newRole" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-2xl"
          title="Create New Role"
          modalType="editRole"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">New Role Added!</p>
              <button
                className="btnPrimary mt-5"
                onClick={() => {
                  handleModal();
                  setShowConfirmation();
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="grid lg:grid-cols-2 gap-4">
              <InputField
                label="role name"
                name="role name"
                placeholder="Enter role name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                required={true}
              />
              <InputField
                label="level"
                name="level"
                type="number"
                min={0}
                placeholder="Role Level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                required={true}
              />
            </div>
          )}
        </Modal>
      )}

      {modalType === "editRole" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-2xl"
          title="Edit Role"
          modalType="editRole"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">Role updated successfully!</p>
              <button
                className="btnPrimary mt-5"
                onClick={() => {
                  handleModal();
                  setShowConfirmation();
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="grid lg:grid-cols-2 gap-4">
              <InputField
                label="role name"
                name="role name"
                placeholder="Enter role name"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
                required={true}
              />
              <InputField
                label="level"
                name="level"
                type="number"
                min={0}
                placeholder="Role Level"
                value={level}
                onChange={(e) => setLevel(e.target.value)}
                required={true}
              />
            </div>
          )}
        </Modal>
      )}

      <div className="flex items-center justify-between">
        <h1 className="elementHeading2">Roles</h1>
        <button className="btnPrimary" onClick={handleNewRole}>
          Create Role
        </button>
      </div>
      <div className="w-full overflow-y-scroll mt-5">
        {" "}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 shadow-sm ">
            <tr>
              <th scope="col" className="tableHead">
                ID.
              </th>
              <th scope="col" className="tableHead">
                Level
              </th>
              <th scope="col" className="tableHead">
                name
              </th>
              <th scope="col" className="tableHead">
                Created At
              </th>
              <th scope="col" className="tableHead">
                Updated At
              </th>
              <th scope="col" className="tableHead">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-gray-900 divide-gray-200 h-full overflow-y-scroll">
            {roles.map((role, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="p-3">
                  <div className="text-sm">{role.id}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{role.level}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{role.name}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{role.created_at}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{role.updated_at}</div>
                </td>
                <td className="p-3">
                  <span className="h-full w-full flex items-center">
                    <button
                      className="text-gray-400 hover:text-yellow-500"
                      onClick={() => handleEditRole(role)}
                    >
                      <PencilSquareIcon className="h-4" />
                    </button>
                    <button className="ml-2 text-gray-400 hover:text-red-400">
                      <TrashIcon className="h-4" />
                    </button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Table
        rows={roles}
        columns={columns}
        slots={{
          toolbar: EditToolbar,
        }}
      /> */}
    </div>
  );
}
