import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { InputField, Modal } from "../../components";

export default function Backlinks() {
  const [backlinks, setBacklinks] = useState([]);
  const [backlink_domain, setBacklinkDomain] = useState("");
  const [black_list, setBlackList] = useState("0");
  const [type, setType] = useState("");

  const [modalType, setModalType] = useState("newBacklink");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editingBacklinkId, setEditingBacklinkId] = useState("");
  const [keyToDelete, setKeyToDelete] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/backlinks/`)
      .then((response) => {
        setBacklinks(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewBacklink = () => {
    setBacklinkDomain("");
    setBlackList("");
    setType("");
    setModalType("newBacklink");
    handleModal();
  };

  const handleDeleteBacklink = async (id) => {
    setModalType("deleteKey");
    setKeyToDelete(id);
    handleModal();
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `https://api.roboanywhere.com/api/backlinks/${keyToDelete}`
      );
      if (response.status === 200) {
        console.log(
          `Row with ID ${keyToDelete} has been deleted successfully.`
        );

        // Update local state by filtering out the deleted row
        setBacklinks((prevData) => {
          return prevData.filter((backlink) => backlink.id !== keyToDelete);
        });
      } else {
        console.log(`Failed to delete row with ID ${keyToDelete}.`);
      }
    } catch (error) {
      console.error(`Error deleting row with ID ${keyToDelete}: ${error}`);
    }
    setKeyToDelete("");
    handleModal();
  };

  const handleEditBacklink = (backlink) => {
    setEditingBacklinkId(backlink.id);
    setBacklinkDomain(backlink.backlink_domain);
    setBlackList(backlink.black_list);
    setType(backlink.type);
    setModalType("editBacklink");
    handleModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const backlink = {
      backlink_domain,
      black_list,
      type,
    };

    if (modalType === "newBacklink") {
      axios
        .post(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/addBacklink`,
          backlink
        )
        .then(() => {
          setShowConfirmation(true);
          setBacklinkDomain("");
          setBlackList("");
          setType("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (modalType === "editBacklink" && editingBacklinkId) {
      axios
        .put(
          `${process.env.REACT_APP_PUBLIC_API}/api/backlinks/updateBacklink`,
          {
            id: editingBacklinkId.toString(),
            backlink_domain: backlink_domain,
            type: type,
            black_list: "0",
          }
        )
        .then(() => {
          setShowConfirmation(true);
          setBacklinkDomain("");
          setBlackList("");
          setType("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <div>
      {modalType === "newBacklink" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-2xl"
          title="Create New Backlink"
          modalType="newBacklink"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">New Backlink Added!</p>
              <button
                className="btnPrimary mt-5"
                onClick={() => {
                  handleModal();
                  setShowConfirmation();
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="grid lg:grid-cols-2 gap-4">
              <InputField
                label="backlink domain"
                name="role name"
                placeholder="Enter backlink domain name"
                value={backlink_domain}
                onChange={(e) => setBacklinkDomain(e.target.value)}
                required={true}
              />
              <InputField
                label="black list"
                name="level"
                type="number"
                min={0}
                placeholder="Black list"
                value={black_list}
                onChange={(e) => setBlackList(e.target.value)}
                required={true}
              />
              <InputField
                label="Backlink type"
                name="level"
                placeholder="Backlink type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required={true}
              />
            </div>
          )}
        </Modal>
      )}

      {modalType === "editBacklink" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-2xl"
          title="Edit Backlink"
          modalType="editBacklink"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">Backlink updated successfully!</p>
              <button
                className="btnPrimary mt-5"
                onClick={() => {
                  handleModal();
                  setShowConfirmation();
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <div className="grid lg:grid-cols-2 gap-4">
              <InputField
                label="backlink domain"
                name="role name"
                placeholder="Enter backlink domain name"
                value={backlink_domain}
                onChange={(e) => setBacklinkDomain(e.target.value)}
                required={true}
              />
              <InputField
                label="black list"
                name="level"
                type="number"
                min={0}
                placeholder="Black list"
                value={black_list}
                onChange={(e) => setBlackList(e.target.value)}
                required={true}
              />
              <InputField
                label="Backlink type"
                name="level"
                placeholder="Backlink type"
                value={type}
                onChange={(e) => setType(e.target.value)}
                required={true}
              />
            </div>
          )}
        </Modal>
      )}

      {modalType === "deleteKey" && (
        <Modal
          modalType="delete"
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleDeleteSubmit}
          className="max-w-lg "
        >
          <span className="text-lg text-center">
            {`Are you sure you want to delete the backlink ${keyToDelete}?`}
          </span>
        </Modal>
      )}

      <div className="flex items-center justify-between">
        <h1 className="elementHeading2">Backlinks</h1>
        <button className="btnPrimary" onClick={handleNewBacklink}>
          Create Backlink
        </button>
      </div>
      <div className="w-full overflow-y-scroll h-[calc(100vh-180px)] mt-5">
        {" "}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 shadow-sm ">
            <tr>
              <th scope="col" className="tableHead">
                ID.
              </th>
              <th scope="col" className="tableHead">
                Backlink Domain
              </th>
              <th scope="col" className="tableHead">
                Backlink Type
              </th>
              <th scope="col" className="tableHead">
                Created At
              </th>
              <th scope="col" className="tableHead">
                Last Updated
              </th>
              <th scope="col" className="tableHead">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-gray-900 divide-gray-200 h-full overflow-y-scroll">
            {backlinks.map((backlink, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="p-3">
                  <div className="text-sm">{backlink.id}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{backlink.backlink_domain}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{backlink.type}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{backlink.created_at}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{backlink.updated_at}</div>
                </td>
                <td className="p-3">
                  <span className="h-full w-full flex items-center">
                    <button
                      className="text-gray-400 hover:text-yellow-500"
                      onClick={() => handleEditBacklink(backlink)}
                    >
                      <PencilSquareIcon className="h-4" />
                    </button>
                    <button
                      className="ml-2 text-gray-400 hover:text-red-400"
                      onClick={() => handleDeleteBacklink(backlink.id)}
                    >
                      <TrashIcon className="h-4" />
                    </button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}