import React, { useState } from "react";
import { FullContainer, InputField } from "../../components";
import axios from "axios";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      username: username,
      password: password,
    };

    try {
      const response = await axios.post(
        "https://api.roboanywhere.com/api/auth",
        data
      );
      localStorage.setItem("user", JSON.stringify(response?.data));
      window.location.reload();
      // Reset username and password fields
      setUsername("");
      setPassword("");
    } catch (error) {
      console.log(error.response.data);
    }
  };

  return (
    <FullContainer className="h-screen bg-login">
      <div className="bg-white w-full lg:max-w-xl p-8 rounded-md shadow-lg flex items-center flex-col">
        <img src="/logo.png" className="h-16" alt="" />
        <form className="w-full mt-4 space-y-4" onSubmit={handleSubmit}>
          <InputField
            name="username"
            label="username"
            type="text"
            placeholder="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <InputField
            name="password"
            label="Password"
            type="password"
            placeholder="Enter password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <p className="text-yellow-500 text-sm mt-3 text-end underline hover:text-yellow-600">
            Forgot Password?
          </p>
          <span className="w-full flex justify-center mt-2">
            <button className="btnPrimary px-7" type="submit">
              Login
            </button>
          </span>
        </form>
      </div>
    </FullContainer>
  );
}
