import {
  HiUserCircle,
  HiUserGroup,
  HiUsers,
  HiViewGrid,
  HiLink,
} from "react-icons/hi";

export const sideMenu = [
  {
    to: "/dashboard",
    label: "Dashboard",
    Icon: HiViewGrid,
  },
  {
    to: "/dashboard/users",
    label: "Users",
    Icon: HiUserGroup,
  },
  {
    to: "/dashboard/roles",
    label: "Roles",
    Icon: HiUserCircle,
  },
  {
    to: "/dashboard/backlinks",
    label: "Backlinks",
    Icon: HiLink,
  },
  {
    to: "/dashboard/groups",
    label: "Groups",
    Icon: HiUsers,
  },
  // {
  //   to: "/dashboard/settings",
  //   label: "Settings",
  //   Icon: HiCog,
  //   children: [
  //     {
  //       to: "/dashboard/settings/merchants",
  //       label: "Merchants",
  //       Icon: HiUsers,
  //     },
  //     {
  //       to: "/dashboard/settings/users",
  //       label: "Users",
  //       Icon: HiUserGroup,
  //     },

  //     {
  //       to: "/dashboard/settings/industry",
  //       label: "Industry",
  //       Icon: HiDatabase,
  //     },
  //     {
  //       to: "/dashboard/settings/hours",
  //       label: "hours",
  //       Icon: HiClock,
  //     },
  //     {
  //       to: "/dashboard/settings/membership",
  //       label: "membership",
  //       Icon: HiUserCircle,
  //     },
  //     {
  //       to: "/dashboard/settings/rating",
  //       label: "rating",
  //       Icon: HiStar,
  //     },
  //     {
  //       to: "/dashboard/settings/status",
  //       label: "status",
  //       Icon: HiTag,
  //     },
  //   ],
  // },
];
