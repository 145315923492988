import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Modal({
  title,
  open,
  cancelButtonRef,
  handleModal,
  children,
  handleModalSubmit,
  className,
  modalType,
  showConfirmation,
}) {
  return (
    <Transition.Root show={!open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleModal}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-full ${className}`}
              >
                <form onSubmit={handleModalSubmit} className="w-full p-7">
                  {!showConfirmation && (
                    <>
                      {title && (
                        <h2 className="elementHeading3 mb-4">{title}</h2>
                      )}
                    </>
                  )}
                  {children}
                  {!showConfirmation && (
                    <>
                      {modalType === "delete" ? (
                        <div className="flex items-cente justify-end mt-4 space-x-2 text-white">
                          <button
                            type="button"
                            className="btnPrimary "
                            onClick={handleModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btnPrimary bg-red-600"
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <div className="flex items-cente justify-end mt-4 space-x-2 text-white">
                          <button
                            type="button"
                            className="btnPrimary bg-red-500"
                            onClick={handleModal}
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className="btnPrimary bg-green-600"
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
