import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { InputField, Modal } from "../../components";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  // New User
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role_id, setRoleId] = useState(0);

  // Update User
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newUsername, setNewUsername] = useState("");
  const [newRoleId, setNewRoleId] = useState(0);

  const [modalType, setModalType] = useState("newUser");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/users/`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/roles/`)
      .then((response) => {
        setRoles(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const handleNewUser = () => {
    setName("");
    setEmail("");
    setUsername("");
    setPassword("");
    setRoleId("");
    setModalType("newUser");
    handleModal();
  };

  const handleEditUser = (user) => {
    setModalType("editUser");
    setEditingUserId(user.id);
    setNewName(user.name);
    setNewEmail(user.email);
    setNewUsername(user.username);
    setNewRoleId(user.role_id);
    handleModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = {
      name,
      email,
      username,
      password,
      role_id,
    };

    if (modalType === "newUser") {
      axios
        .post(`${process.env.REACT_APP_PUBLIC_API}/api/users/addUser`, user)
        .then((response) => {
          console.log(response.data);
          // Show confirmation message
          setShowConfirmation(true);
          // Reset form fields
          setName("");
          setEmail("");
          setUsername("");
          setPassword("");
          setRoleId("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (modalType === "editUser" && editingUserId) {
      axios
        .put(`${process.env.REACT_APP_PUBLIC_API}/api/users/updateUser`, {
          id: editingUserId,
          name: newName,
          email: newEmail,
          role_id: newRoleId,
        })
        .then((response) => {
          console.log(response.data);
          // Show confirmation message
          setShowConfirmation(true);
          // Reset form fields
          setName("");
          setEmail("");
          setUsername("");
          setRoleId("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      {modalType === "newUser" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-2xl"
          title="Create New User"
          modalType="newKey"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">Data added successfully!</p>
              <button className="btnPrimary mt-5" onClick={handleModal}>
                Ok
              </button>
            </div>
          ) : (
            <div className="grid lg:grid-cols-2 gap-4">
              <InputField
                label="name"
                name="name"
                placeholder="Enter full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required={true}
              />
              <InputField
                label="email"
                name="email"
                placeholder="Enter user email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required={true}
              />
              <InputField
                label="username"
                name="username"
                placeholder="Enter username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required={true}
              />
              <InputField
                label="password"
                name="password"
                placeholder="Enter password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required={true}
              />

              <div>
                <label htmlFor="roleId" className="inputLabel">
                  Role ID:
                </label>
                <select
                  value={role_id}
                  onChange={(e) => setRoleId(e.target.value)}
                  name="roleId"
                  className="inputField mt-1"
                  required
                >
                  <option value="">Select a role</option>
                  {roles.map((role, index) => (
                    <option value={role.id}>{role.name}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </Modal>
      )}

      {modalType === "editUser" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-2xl"
          title="Edit User"
          modalType="editUser"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">Data updated successfully!</p>
              <button className="btnPrimary mt-5" onClick={handleModal}>
                Ok
              </button>
            </div>
          ) : (
            <div className="grid lg:grid-cols-2 gap-4">
              <InputField
                label="name"
                name="name"
                placeholder="Enter full name"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                required={true}
              />
              <InputField
                label="email"
                name="email"
                placeholder="Enter user email"
                type="email"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                required={true}
              />
              <InputField
                label="username"
                name="username"
                placeholder="Enter username"
                value={newUsername}
                onChange={(e) => setNewUsername(e.target.value)}
                required={true}
              />

              <div>
                <label htmlFor="roleId" className="inputLabel">
                  Role ID:
                </label>
                <select
                  value={newRoleId}
                  onChange={(e) => setNewRoleId(e.target.value)}
                  name="roleId"
                  className="inputField mt-1"
                  required
                >
                  <option value="">Select a role</option>
                  {roles.map((role, index) => (
                    <option value={role.id}>{role.name}</option>
                  ))}
                </select>
              </div>
            </div>
          )}
        </Modal>
      )}

      <div className="flex items-center justify-between">
        <h1 className="elementHeading2">Users</h1>
        <button className="btnPrimary" onClick={handleNewUser}>
          Create User
        </button>
      </div>
      <div className="w-full overflow-y-scroll h-[calc(100vh-180px)] mt-5">
        {" "}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 shadow-sm ">
            <tr>
              <th scope="col" className="tableHead">
                Sr.
              </th>
              <th scope="col" className="tableHead">
                Name
              </th>
              <th scope="col" className="tableHead">
                Username
              </th>
              <th scope="col" className="tableHead">
                Email
              </th>
              <th scope="col" className="tableHead">
                Created At
              </th>
              <th scope="col" className="tableHead">
                Updated At
              </th>
              <th scope="col" className="tableHead">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-gray-900 divide-gray-200 h-full overflow-y-scroll">
            {users.map((user, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="p-3">
                  <div className="text-sm">{user.id}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{user.name}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{user.username}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{user.email}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{user.role_id.name}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{user.created_at}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{user.updated_at}</div>
                </td>
                <td className="p-3">
                  <span className="h-full w-full flex items-center">
                    <button
                      className="text-gray-400 hover:text-yellow-500"
                      onClick={() => handleEditUser(user)}
                    >
                      <PencilSquareIcon className="h-4" />
                    </button>
                    <button className="ml-2 text-gray-400 hover:text-red-400">
                      <TrashIcon className="h-4" />
                    </button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
