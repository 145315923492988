import {
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridValidRowModel,
  MuiEvent,
} from "@mui/x-data-grid";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey(
  "b572720c5111a0d0cf3d6c75a9f06220Tz02MTk2NixFPTE3MTAzOTYwOTIyNzIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

export default function Table({
  rows,
  columns,
  loading,
  checkboxSelection,
  disableMultipleRowSelection,
  rowModesModel,
  setRowModesModel,
  processRowUpdate,
  slots,
  handleFileUpload,
  setRows,
  setSelectedRowIds,
}) {
  const apiRef = useGridApiRef();

  const handleRowEditStart = (GridRowParams, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  return (
    <div className="shadow-lg bg-white h-[calc(100vh-170px)] w-full p-2">
      <DataGridPro
        rows={rows}
        columns={columns}
        editMode="row"
        rowHeight={40}
        columnHeaderHeight={36}
        loading={loading}
        checkboxSelection={!!checkboxSelection}
        disableMultipleRowSelection={!!disableMultipleRowSelection}
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStart={handleRowEditStart}
        onRowEditStop={handleRowEditStop}
        onRowSelectionModelChange={(rowSelectionModel) => {
          setSelectedRowIds && setSelectedRowIds(rowSelectionModel);
        }}
        processRowUpdate={processRowUpdate}
        slots={slots}
        slotProps={{
          toolbar: {
            rowModesModel,
            setRows,
            setRowModesModel,
            handleFileUpload,
            disable: loading,
          },
        }}
        onProcessRowUpdateError={console.error}
        classes={{
          columnHeader: "bg-[#F7C98B] shadow-sm",
          columnHeaderTitle: "tableHead font-bold text-md capitalize px-0",
          row: "text-md",
        }}
        apiRef={apiRef}
        initialState={{ pinnedColumns: { left: ["__check__"] } }}
        // experimentalFeatures={{ columnGrouping: true }}
        // columnGroupingModel={columnGroupingModel}
      />
    </div>
  );
}
