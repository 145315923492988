import { useState } from "react";
import DashboardHead from "./DashboardHead";
import Sidebar from "../../components/Menu/Sidebar";
import Login from "../Login";

export default function Dashboard({ isLoading, children, user, setUser }) {
  const [show_navlabel, setShowNavbar] = useState(true);

  const toggleNavbar = () => {
    setShowNavbar(!show_navlabel);
  };

  return (
    <>
      {isLoading ? (
        <div className="flex flex-col w-full justify-center text-center h-screen">
          Loading...
        </div>
      ) : user ? (
        <>
          <DashboardHead
            toggleNavbar={toggleNavbar}
            show_navlabel={show_navlabel}
            setUser={setUser}
          />
          <div
            className={
              show_navlabel ? "grid grid-cols-body h-screen" : "w-full h-screen"
            }
          >
            {show_navlabel && (
              <Sidebar show_navlabel={show_navlabel} setUser={setUser} />
            )}
            <div
              className={
                show_navlabel
                  ? "p-7 pt-20 overflow-y-scroll"
                  : "p-7 pt-16 overflow-y-scroll"
              }
            >
              {children}
            </div>
          </div>
        </>
      ) : (
        <Login setUser={setUser} />
      )}
    </>
  );
}
