import React from "react";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { Topbar } from "../../components";

export default function DashboardHead({
  show_navlabel,
  toggleNavbar,
  setUser,
}) {
  return (
    <div className="border-b bg-white fixed top-0 w-full z-40">
      <header
        className={
          show_navlabel ? "grid grid-cols-body" : "grid grid-cols-bodyClosed"
        }
      >
        <div className="flex border-r px-5 py-1 items-center">
          {show_navlabel ? (
            <Bars3Icon onClick={toggleNavbar} className="cursor-pointer w-6" />
          ) : (
            <Bars3Icon
              onClick={toggleNavbar}
              className="cursor-pointer w-6 ml-auto mr-auto"
            />
          )}
          {show_navlabel && (
            <img src="/logo.png" className="h-12 ml-5" alt="" />
          )}
        </div>
        <div className="flex items-center px-5 py-1">
          {show_navlabel ? (
            <h1 className="text-lg">Dashboard</h1>
          ) : (
            <Topbar setUser={setUser} show_navlabel={undefined} />
          )}
        </div>
      </header>
    </div>
  );
}
