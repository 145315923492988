import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Backlinks, Dashboard, Groups, Users } from "./pages";
import Roles from "./pages/Roles";

export default function Router() {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  
  let routes;
  if (user) {
    routes = [
      { path: "/dashboard", element: <h1>Dashboard</h1> },
      { path: "/dashboard/users", element: <Users /> },
      { path: "/dashboard/roles", element: <Roles /> },
      { path: "/dashboard/backlinks", element: <Backlinks /> },
      { path: "/dashboard/groups", element: <Groups /> },
      { path: "*", element: <Navigate to="/dashboard" replace /> },
    ];
  } else {
    routes = [{ path: "*", element: <Navigate to="/" replace /> }];
  }
  return (
    <BrowserRouter>
      <Dashboard user={user}>
        <Routes>
          {routes?.map((route, index) => (
            <Route key={index} {...route} />
          ))}
        </Routes>
      </Dashboard>
    </BrowserRouter>
  );
}
