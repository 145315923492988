import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/solid";
import { InputField, Modal } from "../../components";

export default function Groups() {
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [group_name, setGroupName] = useState("");
  const [assignedBacklink, setAssignedBacklink] = useState([]);

  const [modalType, setModalType] = useState("newGroup");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [keyToDelete, setKeyToDelete] = useState("");

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PUBLIC_API}/api/groups/`)
      .then((response) => {
        setGroups(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleNewGroup = () => {
    setGroupName("");
    setModalType("newGroup");
    handleModal();
  };

  const handleEditGroup = (group) => {
    setEditingGroupId(group.id);
    setGroupName(group.group_name);
    setModalType("editGroup");
    handleModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const group = {
      group_name,
    };

    if (modalType === "newGroup") {
      axios
        .post(`${process.env.REACT_APP_PUBLIC_API}/api/groups/addGroup`, group)
        .then(() => {
          setShowConfirmation(true);
          setGroupName("");
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (modalType === "editGroup" && editingGroupId) {
      axios
        .put(`${process.env.REACT_APP_PUBLIC_API}/api/groups/updateGroup`, {
          id: editingGroupId.toString(),
          group_name,
        })
        .then(() => {
          setShowConfirmation(true);
          setGroupName("");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDeleteGroup = async (id) => {
    setModalType("deleteKey");
    setKeyToDelete(id);
    handleModal();
  };

  const handleDeleteSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_PUBLIC_API}/api/groups/${keyToDelete}`
      );
      if (response.status === 200) {
        console.log(
          `Row with ID ${keyToDelete} has been deleted successfully.`
        );
        setGroups((prevData) => {
          return prevData.filter((group) => group.id !== keyToDelete);
        });
      }
    } catch (error) {
      console.error(`Error deleting row with ID ${keyToDelete}: ${error}`);
    }
    setKeyToDelete("");
    handleModal();
  };

  // Modal
  const [open, setOpen] = useState(true);
  const cancelButtonRef = useRef(null);
  const handleModal = () => {
    setOpen(!open);
  };

  const manageBacklinks = (group) => {
    setAssignedBacklink([]);
    setSelectedGroup(group.group_name);
    setModalType("manageBacklinks");
    axios
      .get(
        `${process.env.REACT_APP_PUBLIC_API}/api/groupAssignedBacklink/${group.id}`
      )
      .then((res) => {
        setAssignedBacklink(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    handleModal();
  };

  return (
    <div>
      {modalType === "manageBacklinks" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit=""
          className="max-w-2xl"
          modalType="editRole"
          showConfirmation=""
        >
          <div className="w-full flex justify-between mb-5">
            <h3 className="elementHeading3">{`Manage Backlinks of ${selectedGroup}`}</h3>
            <button className="btnPrimary text-sm">Update Backlinks</button>
          </div>
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50 sticky top-0 shadow-sm ">
              <tr>
                <th scope="col" className="tableHead">
                  Backlink Id.
                </th>
                <th scope="col" className="tableHead">
                  Backlink Domain
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y text-gray-900 divide-gray-200 h-full overflow-y-scroll">
              {assignedBacklink?.map((domain, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="p-3 py-2">
                    <div className="text-sm">{domain.backlink_id}</div>
                  </td>
                  <td className="p-3 py-2">
                    <div className="text-sm">{domain.backlink_domain}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
      {modalType === "newGroup" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-xl"
          title="Create New Group"
          modalType="editRole"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">New Group Added!</p>
              <button
                className="btnPrimary mt-5"
                onClick={() => {
                  handleModal();
                  setShowConfirmation();
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <InputField
              label="group name"
              name="role name"
              placeholder="Enter new group name"
              value={group_name}
              onChange={(e) => setGroupName(e.target.value)}
              required={true}
            />
          )}
        </Modal>
      )}

      {modalType === "editGroup" && (
        <Modal
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleSubmit}
          className="max-w-xl"
          title="Edit Group"
          modalType="editGroup"
          showConfirmation={showConfirmation}
        >
          {showConfirmation ? (
            <div className="flex flex-col items-center justify-center">
              <p className="text-2xl">Group updated successfully!</p>
              <button
                className="btnPrimary mt-5"
                onClick={() => {
                  handleModal();
                  setShowConfirmation();
                }}
              >
                Ok
              </button>
            </div>
          ) : (
            <InputField
              label="group name"
              name="role name"
              placeholder="Enter new group name"
              value={group_name}
              onChange={(e) => setGroupName(e.target.value)}
              required={true}
            />
          )}
        </Modal>
      )}
      {modalType === "deleteKey" && (
        <Modal
          modalType="delete"
          open={open}
          handleModal={handleModal}
          cancelButtonRef={cancelButtonRef}
          handleModalSubmit={handleDeleteSubmit}
          className="max-w-lg "
        >
          <span className="text-lg text-center">
            {`Are you sure you want to delete the group ${keyToDelete}?`}
          </span>
        </Modal>
      )}

      <div className="flex items-center justify-between">
        <h1 className="elementHeading2">Groups</h1>
        <button className="btnPrimary" onClick={handleNewGroup}>
          Create Group
        </button>
      </div>
      <div className="w-full overflow-y-scroll h-[calc(100vh-180px)] mt-5">
        {" "}
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50 sticky top-0 shadow-sm ">
            <tr>
              <th scope="col" className="tableHead">
                ID.
              </th>
              <th scope="col" className="tableHead">
                Group Name
              </th>
              <th scope="col" className="tableHead">
                Manage Backlink
              </th>
              <th scope="col" className="tableHead">
                Created At
              </th>
              <th scope="col" className="tableHead">
                Updated At
              </th>
              <th scope="col" className="tableHead">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-gray-900 divide-gray-200 h-full overflow-y-scroll">
            {groups.map((group, index) => (
              <tr key={index} className="hover:bg-gray-100">
                <td className="p-3">
                  <div className="text-sm">{group.id}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{group.group_name}</div>
                </td>
                <td className="p-3">
                  <button
                    className="btnSecondary text-sm"
                    onClick={() => manageBacklinks(group)}
                  >
                    Manage Backlinks
                  </button>
                </td>
                <td className="p-3">
                  <div className="text-sm">{group.created_at}</div>
                </td>
                <td className="p-3">
                  <div className="text-sm">{group.updated_at}</div>
                </td>
                <td className="p-3">
                  <span className="h-full w-full flex items-center">
                    <button
                      className="text-gray-400 hover:text-yellow-500"
                      onClick={() => handleEditGroup(group)}
                    >
                      <PencilSquareIcon className="h-4" />
                    </button>
                    <button
                      className="ml-2 text-gray-400 hover:text-red-400"
                      onClick={() => handleDeleteGroup(group.id)}
                    >
                      <TrashIcon className="h-4" />
                    </button>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
