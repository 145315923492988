import { Link } from "react-router-dom";
import Dropdown from "./Dropdown";
import { sideMenu } from "./Menu";
import { BiLogOut } from "react-icons/bi";
import { useEffect, useState } from "react";
import { ChatBubbleBottomCenterIcon } from "@heroicons/react/24/solid";

const Topbar = ({ show_navlabel, setUser }) => {
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");

  //to keep track of the active menu item even page refreshes
  useEffect(() => {
    const path = window.location.pathname;
    const label = sideMenu.find((item) => item?.to === path);
    if (label) {
      setActiveMenuItem(label.label);
    }
  }, []);

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center">
        <img src="/logo.png" className="h-12 mr-5" alt="" />
        {sideMenu.map((item, key) => {
          const { label, to, Icon, children } = item;
          const isActive = activeMenuItem === item.label;
          return (
            <div key={key} className="sidebar-item">
              {item?.children ? (
                <Dropdown
                  show_navlabel={show_navlabel}
                  label={label}
                  activeMenuItem={activeMenuItem}
                  setActiveMenuItem={setActiveMenuItem}
                  to={to}
                  Icon={Icon}
                  options={children}
                />
              ) : (
                <Link
                  className={`pageLink ${isActive && "bg-gray-200 px-4"}`}
                  title={label}
                  to={to}
                  onClick={() => setActiveMenuItem(item.label)}
                >
                  <Icon />
                  <span className="ml-1">{label}</span>
                </Link>
              )}
            </div>
          );
        })}
        <Link className="pageLink">
          <ChatBubbleBottomCenterIcon className="w-4" />{" "}
          <span className="ml-1">Support </span>
        </Link>
      </div>
      <div className="flex items-center">
        <Link
          className="pageLink text-red-400"
          onClick={() => localStorage.clear()}
        >
          <BiLogOut />
          <span className="ml-1">Logout</span>
        </Link>
      </div>
    </div>
  );
};

export default Topbar;
